#index {
  min-height: 100vh;

  form {
    z-index: $zindex-searchbar;
  }
}

.home {

  &__search {
    background-image: url(/static/images/home/background.svg);
    background-position: bottom;
    background-size: cover;
    width: 101%;
  }

  position: relative;

  .clip-svg {
    @include mq('mobile-only') {
      display: none;
    }
  }

  @include mq('mobile-only') {
    .link-info {
      color: $white;
    }
  }

  .home {
    &__search {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 50px 20px 0;

      @include mq('tablet') {
        padding: 15vmin 0;
      }

      .logo--big {
        margin-bottom: $spacing-5;
        width: auto;
        height: 54px;
        fill: white;
        @include mq('tablet') {
          height: 110px;
        }
      }

      .subtitle {
        color: $white;
        margin-bottom: $spacing-6;
        padding: 0;
        @include mq('tablet') {
          max-width: 410px;
        }
        @include mq('desktop') {
          max-width: 60vw;
        }
        @include mq('mobile-only') {
          font-size: $text-xl;
        }
      }
    }
  }

  .searchbar {
    align-self: center;
  }
}

//TODO cristela ==> clean media queries
.home-connected {
  .bloc-connected {
    h3 {
      flex-basis: 100%;
      margin-bottom: $minicard-gap;
      color: color-ui(main);
    }

    .minicard {
      @include mq('tablet-only') {
        width: 368px; // Make sure we have enough space for two minicard on one row
      }
    }
  }

  .empty_rdv {
    @media #{$phone} {
      p {
        color: $white;
      }
    }

    img {
      width: 25vw;
      max-width: 600px;
      @media #{$tab} {
        width: 30vw;
      }
      @media #{$phone} {
        width: 70vw;
        margin-top: -100px;
      }
    }

    p {
      font-size: $text-large;
      @media #{$phone} {
        font-size: $text-default;
      }
    }

    .btn {
      margin-top: $spacer-40;
    }
  }
}

.home.connected {
  .home-connected {
    top: 0;
    margin-bottom: 50px;
    @include mq('tablet') {
      top: 0;
      margin-bottom: 0;
    }
    @include mq('desktop') {
      top: 80px;
      margin-bottom: 150px;
    }
  }
}
