button {
  font-size: initial; // reset font size
}

a,
button {
  transition: all 0.3s ease;
}

//========================================

.icon_switcher {
  margin-left: $spacing-2;
  margin-top: $spacing-1;
  cursor: pointer;
  @include mq('tablet') {
    margin-top: 0;
  }
  &.favorite {
    &.selected {
      fill: $star;
    }
  }
  &.referring {
    &.selected {
      fill: color-ui(accent);
    }
  }
}

.document-card.is-dragging {
  opacity: 0.5;
}

.patient-avatar.btn.disabled {
  background-color: inherit !important;
}

.MuiAlert-standardError.form-error {
  color: $error;
  grid-area: error;
  margin-bottom: $spacer-12;
}
/* btn form card stripes*/
.MuiGrid-item.MuiGrid-align-content-xs-flex-start {
  grid-area: button;
}

/*  BLOC HIGHLIGHT  */

.bloc-highlight {
  background: $blue-highlight;
  border-radius: $radius-default;
  color: $color-text;
  padding: $spacer-12;
  text-align: center;
  margin-bottom: $spacer-32;
  p {
    font-weight: 500;
  }
  &.is-success {
    padding-top: $spacer-40;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -25px;
      left: calc(50% - 25px);
      height: 50px;
      width: 50px;
      border-radius: 100%;
      color: $white;
      background-color: $success;
      background-image: url(/static/icons/check.svg);
      background-size: 30px;
      background-position: center center;
    }
  }
  p {
    font-weight: 500;
  }
  .btn {
    margin: $spacer-12 auto 0;
  }
  .highlight-text {
    color: color-ui(accent);
  }
}

.patient.camera-mirror .OT_video-element {
  transform: scale(-1, 1);
  transform-origin: 50% 50%;
}
.locked-page {
  white-space: pre;
  max-width: 750px;
  padding: 20px;
  margin: 150px auto 0;
  text-align: center;
}
