//TODO rework not all TLC
.settings-list {
  background: $white;
  padding: $spacer-12;
  z-index: 200;
  border-radius: $radius-default;
  margin-left: $spacer-16;
  color: $color-text;
  &:before {
    content: '';
    position: absolute;
    bottom: 12px;
    left: 7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 9px 8px 0;
    border-color: transparent $white transparent transparent;
  }
  p {
    font-size: $text-small;
    font-style: italic;
  }
  .MuiSelect-select {
    width: 150px;
    height: 40px;
  }
  select {
    & + p {
      margin-top: $spacer-16;
    }
  }
}

.tlc-cards--empty {
  .add-row {
    justify-content: center;
    margin: 10px 0 $spacer-24;
    opacity: 1;
  }
}

.accordeon {
  &.inprogress {
    .step {
      background-color: color-ui(accent);
    }
  }
  &.invalid {
    .step {
      background-color: $error;
    }
  }
  &.inactive {
    .step {
      background-color: $color-grey--lite-alt;
    }
  }
}
.tlc-cards--list--item {
  width: 100%;
}

.tlc-init {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: hsla(0, 0%, 100%, 0.9);
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  padding-bottom: 50px;
  color: $white;
}

.tlc-init {
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0;
  }
  .aide-support {
    margin-top: $spacer-16;
    padding: 0 20px;
  }
  .wrapper-headings {
    margin: $spacer-16 0 $spacer-16;
    text-align: center;
    .title {
      color: color-ui(main);
      font-size: $title-xs;
      max-width: 500px;
    }
    .subtitle {
      font-size: $text-small;
      color: $color-placeholder;
    }
    @include mq('tablet') {
      margin: $spacer-32 0 $spacer-40;
    }
  }
  .wrapper-conditions {
    max-width: 440px;
    padding: $spacer-8;
    background-color: $blue-grey-background;
    @include mq('tablet') {
      padding: $spacer-8 $spacer-16;
      margin: 0 20px;
    }
  }
  .accordeon {
    width: 500px;
    box-shadow: none;
    margin: 0 auto;
    position: relative;

    @include mq('tablet') {
      margin: 10px auto;
    }

    @include mq('mobile-only') {
      width: calc(100vw - 30px);
    }
    &:before {
      display: none;
    }

    &__summary {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 $spacer-16 0 $spacer-8;
    }

    &.access {
      .accordeon__body {
        display: flex;
        flex-direction: column;
        .items {
          display: flex;
          justify-content: space-evenly;
          margin: $spacer-16 0;
        }
        > .btn {
          margin: 30px auto 0 auto;
          padding: $spacer-8 $spacer-32;
        }
        .paragraph {
          & + .items {
            margin-top: $spacer-24;
          }
        }
      }
    }
    .step {
      grid-area: num;
      flex-shrink: 0; // Make sure step number don't get shrinked
      background-color: color-ui(main);
      color: white;
      display: block;
      @include squaresize(25px);
      @extend %flexcenter;
      text-align: center;
      font-size: $text-xl;
      border-radius: 50%;
      margin-right: 10px;
      &.active {
        background-color: color-ui(accent);
      }
    }
    .title {
      grid-area: title;
      color: $color-text;
      font: 500 14px/16px $font-outfit;
    }
    .value {
      margin-left: $spacer-4;
      grid-area: value;
      font-weight: 400;
      color: $color-grey--medium;
      max-width: 360px;
    }
    &.access .accordeon__body {
      display: flex;
      justify-content: space-evenly;
      .icon-text {
        @extend %flexcenter;
        flex-direction: column;
        svg {
          @include squaresize(24px);
          fill: $color-text;
          &.ok {
            fill: colors(green, 400);
          }
          &.ko {
            fill: colors(red, 400);
          }
        }
      }
    }
  }
  .backgroundvideo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
    margin: 0 auto;
    z-index: -1;
    &::before {
      position: absolute;
      display: block;
      content: '';
      background-color: black;
      left: 0;
      right: 0;
    }
  }
  .MuiAccordionSummary-content {
    align-items: center;
  }
  .accordeon.access .accordeon__body .begin-tlc {
    text-align: center;
    border-radius: 18px;
    margin-top: 0;
    padding-top: $spacer-16;
    padding-bottom: $spacer-16;
    position: relative;
    font: 400 14px/20px $font-source-sans-3;
    p {
      display: block;
      font-weight: 700;
    }
  }
  .submit-block {
    margin-top: $spacer-24;
    .begin-tlc {
      padding: $spacer-8 $spacer-32;
      font-size: $title-xs;
      position: relative;
      min-width: 300px;
      line-height: 24px;
      @include mq('tablet') {
        font-size: $title-small;
        padding: $spacer-16 $spacer-40;
      }
      .submit-block--price-info {
        font-size: $text-small;
        @include mq('tablet') {
          font-size: $text-default;
        }
      }

      .loader-button {
        position: absolute;
        top: 18px;
        right: 50px;
      }
    }
  }
}
$background-tlc: $chalkboard-dark;
.tlc-video {
  overflow-x: hidden;
  --patient-width: 264px;
  //width: 100vw;
  background-color: $background-tlc;

  .wrapper-video {
    max-width: 2240px;
    margin: 0 auto;
    height: calc(100vh - var(--header-bar));
    position: relative;
  }
  .subscriber {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    max-width: 2240px;
    margin: 0 auto;
    > div {
      height: 100%;
      width: 100%;
    }
    .screen {
      // div added when the practitioner does screen sharing
      // it needs to be above the practitioner webcam
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
    .alert {
      width: auto;
      height: initial;
      padding: $spacing-3;
      margin: $spacing-3;

      &.is-warning {
        span {
          color: $warning-dark;
        }
      }
    }
    &.waiting {
      background-color: $white-cream;
      max-width: 100vw;
      margin: 0;
      @extend %flexcenter;
      flex-direction: column;
      .info {
        width: 80%;
        max-width: 400px;
        text-align: center;
        font-weight: 500;
        font-size: $title-xs;
        color: $color-text;

        .highlight {
          display: block;
          margin-bottom: $spacing-2;
          color: color-ui(main);
        }
      }
      .footnote {
        font-size: $text-default;
        color: $color-grey--default;
        font-style: italic;
      }
      .countdown {
        font-size: $text-xl;
        color: $color-grey--default;
        font-style: italic;
        // Display characters as if they were in a table with fixed width cells, to prevent layout shift when
        // changing between numbers with different widths, eg. "2" to "1"
        // https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-numeric
        // NOT ACTIVE: https://stackoverflow.com/questions/54713764/font-variant-numeric-for-google-fonts
        font-variant-numeric: tabular-nums;
      }
      .circle-loader {
        @include squaresize($spacing-9);
        margin: $spacing-5 auto;
      }
      &.pending {
        .circle-loader {
          filter: saturate(0);
        }
      }
    }
  }
  .patient {
    position: absolute;
    bottom: 2vmin;
    left: 2vmin;
    z-index: 1;
    width: var(--patient-width);
    @include mq('mobile-only') {
      width: 35%;
      .OT_publisher {
        width: auto !important;
      }
    }
  }

  .overlay-pro-browsing-documents {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-grow: 1;

    & .illus {
      background: url(/static/images/default_document.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 60vmin;
      width: 60vmin;
    }
  }

  .wrapper-actions {
    position: absolute;
    bottom: 2vmin;
    left: calc(var(--patient-width) + 40px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include mq('mobile-only') {
      right: 16px;
      bottom: 16px;
      left: unset;
    }
    .btn--big {
      padding: $spacer-8 $spacer-16;
    }
  }
  .video-control {
    margin-top: $spacer-24;
    > div {
      margin-left: $spacer-8;
    }
  }
  .actions {
    .btn {
      & + .btn {
        margin: 0;
      }
    }
    .doc {
      margin-top: $spacer-16;
    }
    .settings-video {
      display: flex;
      @include mq('mobile-only') {
        justify-content: center;
      }
      .btn--icon {
        @include squaresize(40px);
        > svg {
          @include squaresize(24px);
        }
        & + .btn--icon {
          margin-left: $spacer-16;
        }
      }
      .settings {
        margin-left: 48px;
      }
    }

    > .btn {
      margin-right: 25px;
      @include mq('mobile-only') {
        margin-right: 0;
        padding: 4px 8px;
        font-size: $text-default;
      }
    }
  }
  .OT_widget-container {
    background-color: $background-tlc !important;
  }
}
.tlc-refused {
  margin: var(--header-bar) auto 0;
  padding: var(--header-bar) $spacing-4;
  min-height: var(--content-height);
  max-width: 670px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .suggestions-list {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    & > * {
      &:not(:last-child) {
        margin-bottom: $spacing-4;
      }
    }

    &.empty {
      align-items: center;
      font-size: $text-large;
      text-align: center;

      .highlight {
        display: block;
        margin-bottom: $spacing-2;
        color: color-ui(main);
      }

      svg {
        width: 50%;
        max-width: $spacing-12;
        height: auto;
        margin-bottom: $spacing-6;
      }

      // When loading list it will be empty first, this hack to delay the
      // visibility of empty list screen and show search card skeleton instead
      // to give some time to load TLC cards
      @keyframes reveal-delay {
        from {
          opacity: 0;
          height: 0;
        }
        to {
          opacity: 1;
          height: auto;
        }
      }

      .search-card {
        width: 100%;
        animation: reveal-delay 0s 2s reverse forwards;
      }

      > *:not(.search-card) {
        animation: reveal-delay 2s step-end;
      }
    }
  }
  .search-card--tlc {
    margin-top: 0;
    margin-bottom: 0;
  }
  .search-card {
    width: auto;
  }
}
.tlc-finished,
.tlc-other-active {
  width: 100vw;
  height: calc(100vh - var(--header-bar));
  min-height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $white-opacity-ninety;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  padding: 20px;
  h2 {
    color: color-ui(main);
    text-align: center;
  }
  .tlc__item--title {
    color: $color-text;
    font: 400 14px/16px $font-outfit;
    white-space: pre-line;
  }
}

.tlc-other-active {
  background-color: $white-cream;

  .subtitle {
    display: block;
    margin-top: $spacing-2;
    color: $color-text;
    font-weight: normal;
    font-size: $text-large;
  }
}

.tlc__item {
  background: $white;
  padding: 20px 10px;
  max-width: 460px;
  border-radius: $radius-xs;
  text-align: center;
  @include mq('mobile-only') {
    width: 100%;
    max-width: 410px;
  }
  & + .tlc__item {
    margin-top: 20px;
  }
  & + .btn {
    margin-top: 25px;
  }
}
.tlc__documents {
  display: grid;
  grid-template-columns: repeat(2, 200px);
  grid-template-rows: auto;
  grid-gap: $spacing-5;
  padding: $spacing-5 0;
  overflow-y: auto;
  max-height: 400px;
  justify-content: center;
  text-align: left;

  @include mq('mobile-only') {
    grid-gap: $spacing-3;
    grid-template-columns: repeat(2, 150px);
  }
}

.btn-hangup {
  color: $error;
}

.tlc-lost {
  background-color: $white-blue;

  .content-wrapper {
    @extend %flexcenter;
    flex-direction: column;
    width: 100%;
    max-width: $spacing-14;
    text-align: center;
    padding: 0 $spacing-6 $spacing-10 $spacing-6;
    margin: 0 auto;

    img {
      width: 100%;
      margin-bottom: $spacing-4;
    }
  }
}

.MuiListItem-root.MuiMenuItem-root.tlc-cards--list--item {
  max-width: 100%;
  .card-row {
    padding: 5px;
    margin-left: -8px;
    width: calc(100% + 8px);
  }
}

.MuiSelect-select,
.MuiMenu-paper {
  .card-row {
    &:hover {
      border-color: transparent;
      background-color: unset;
    }
  }
}
