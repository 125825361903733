.related-articles {
  p {
    font-weight: 500;
  }
  ul {
    padding-left: $spacing-5;

    li {
      margin-top: $spacing-2;
      list-style-type: disc;

      a {
        color: color-ui(main);
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

#related-articles {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: $spacing-6;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(to right, transparent, $color-grey--background);
  }

  .title {
    font-size: 20px;
  }

  .cards-list {
    margin: $spacing-4 0;
    display: flex;
    width: 100%;
    padding: $spacing-5;
    overflow-x: auto;
  }

  a {
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .related-article-card {
    list-style-type: none;
    border-radius: $spacing-1;
    overflow: hidden;
    box-shadow: 0 $spacing-2 $spacing-5 rgba(0, 0, 0, 0.1);
    flex: 1 0 $spacing-11; // flex: grow shrink basis;
    transition: box-shadow 0.15s ease-out;

    &:hover {
      box-shadow: 0 2px $spacing-2 rgba(0, 0, 0, 0.1);
    }

    &:not(:last-child) {
      margin-right: $spacing-3;
    }

    .card-image {
      height: 150px;
      width: 100%;
      background-color: color-ui(main, xlight);
      background-size: cover;
      background-position: center center;
    }

    h4,
    p {
      font-size: 14px;
      margin: $spacing-2 $spacing-4 $spacing-5;
    }

    .article-reading-time {
      font-size: $spacing-3;
      font-weight: 600;
      margin-top: auto;
      margin-bottom: $spacing-1;
    }

    .article-date {
      font-size: $spacing-3;
      font-style: italic;
    }
  }

  @include mq('desktop') {
    &::after {
      display: none;
    }

    .title {
      font-size: $spacing-6;
      margin: $spacing-5 0;
    }

    .related-article-card {
      &:not(:last-child) {
        margin-right: $spacing-4;
      }

      .card-image {
        height: 280px;
      }

      h4,
      p {
        font-size: $spacing-5;
      }

      .article-reading-time,
      .article-date {
        font-size: 14px;
      }
    }
  }
}
